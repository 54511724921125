import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import Actions from '../../actions';
import './Booking.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import BookingSlot from '../../components/BookingSlot';
import Logout from '../../components/Logout';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';

class Booking extends Component {

    state = {
        curTime: moment().format("hh:mm A"),
        time: '',
        outlet: null,
        selectedDate: moment(),
        remark: '',
        staff: '',
        room: null,
        from: null,
        duration: null,
        timeSlotsFromAll: null,
    }

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        const { match, selected, bookingSlotInfo } = this.props;
        // const { selectedDate } = this.state;

        let room = match.params.room;

        this.setState({
            curTime: moment().format("hh:mm A"),
            room: room,
        })

        if (bookingSlotInfo.selectedDate) {
            this.setState({
                // timeSlotsFromAll: bookingSlotInfo.timeSlots,
                selectedDate: bookingSlotInfo.selectedDate,
            })
        }
        this.props.bookingSlotInfoDispatch({});
        this.props.roomDetail({ outlet: selected.outlet, room, selectedDate: bookingSlotInfo.selectedDate });

        // Update current time every 10 secs
        setInterval(() => {
            this.setState({ curTime: moment().format("hh:mm A"), })
        }, 10000)
    }

    componentWillUnmount() {
        clearInterval(this.state.curTime);
    }

    refreshRoom = date => {
        const { selected } = this.props;
        const { room } = this.state;

        this.setState({
            selectedDate: date,
        })
        this.props.roomDetail({ outlet: selected.outlet, room, selectedDate: date });
    }

    handleInputRemarkChange = event => {
        const inputValue = event.target.value;
        this.setState({
            remark: inputValue
        });
    }

    handleInputStaffChange = event => {
        const inputValue = event.target.value;
        this.setState({
            staff: inputValue
        });
    }

    onUpdateSlot = timeslot => {
        this.setState({
            from: timeslot.from,
            duration: timeslot.duration.toString(),
            time: timeslot.time,
        });
    }

    onConfirmModal = () => {
        const { selectedDate, room, remark, staff, from, duration } = this.state;
        let bookingPayload = {
            date: moment(selectedDate).format('YYYY-MM-DD'),
            remarks: remark,
            name: staff,
            room_id: room,
            from: from,
            duration: duration,
        }
        this.props.booking(bookingPayload, this.onBookingSuccess);
    }

    onBookingSuccess = () => {
        this.props.history.push('/confirm');
    }

    render() {
        const { room, member, error, isLoading } = this.props;
        const { selectedDate } = this.state;

        let modalBody = (
            <div className="bookingInfo">
                <p><span className="bookingSumTitle">Type of Space</span> <span className="bookingSumRte"><em>:</em> {get(room, 'name', [])}</span></p>
                <p><span className="bookingSumTitle">Date</span> <span className="bookingSumRte"><em>:</em> {moment(selectedDate).format('MMM DD, YYYY')} </span></p>
                <p><span className="bookingSumTitle">Time</span> <span className="bookingSumRte"><em>:</em> {this.state.time} </span></p>
                <p><span className="bookingSumTitle">Meeting Name</span> <span className="bookingSumRte"><em>:</em>{this.state.remark}</span></p>
                <p><span className="bookingSumTitle">Staff Name</span> <span className="bookingSumRte"><em>:</em>{this.state.staff}</span></p>
            </div>
        );

        return (
            <div className="booking">
                <div className="container mt-4 mt-md-5">
                    <a className="lightText back" onClick={this.goBack} href={null}>Back</a>
                    <Logout />
                    <span className="lightTextTime">{moment().format('MMM DD, YYYY')}</span>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 mt-5 bookingMain">
                            <h3>PLEASE SELECT YOUR SLOT
                                <div className="bookingDate">
                                    <em className="arrow prev" onClick={() => this.refreshRoom(selectedDate.subtract(1, "days"))}></em>
                                    {moment(selectedDate).format('MMM DD, YYYY')}
                                    <em className="arrow next" onClick={() => this.refreshRoom(selectedDate.add(1, "days"))}></em>
                                </div>
                            </h3>
                            <form action="" method="post">
                                <div className="bookingSlot">
                                    {isLoading ? <Loading /> :
                                        <div className="form-group" >
                                            <PerfectScrollbar>
                                                <BookingSlot booking={get(room, 'bookings', [])} roomId={get(room, 'id', [])} onSlotUpdated={this.onUpdateSlot} updatedTimeSlots={this.state.timeSlotsFromAll} />
                                            </PerfectScrollbar>
                                        </div>
                                    }
                                    <div className="error-message">
                                        {get(error, 'errors', [])}
                                    </div>
                                    <ul>
                                        <li><span className="bookingAvailable">.</span> Available</li>
                                        <li><span className="bookingOccupied">.</span> Occupied</li>
                                        <li><span className="bookingSelected">.</span> Selected</li>
                                    </ul>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-lg-3 bookingImg">
                                        <img src={get(room, 'photo', [])} alt={get(room, 'name', [])} />
                                    </div>
                                    <div className="col-md-9 col-lg-9 bookingInfo">
                                        <h3>BOOKING SUMMARY</h3>
                                        <p><span className="bookingSumTitle">Type of Space</span> <span className="bookingSumRte"><em>:</em> {get(room, 'name', [])}</span></p>
                                        <p><span className="bookingSumTitle">Booked by</span> <span className="bookingSumRte"><em>:</em> {get(member, "first_name", [])} {get(member, "last_name", [])}</span></p>
                                        <p><span className="bookingSumTitle">Date</span> <span className="bookingSumRte"><em>:</em> {moment(selectedDate).format('MMM DD, YYYY')} </span></p>
                                        <p><span className="bookingSumTitle">Time</span> <span className="bookingSumRte"><em>:</em> {this.state.time} </span></p>
                                        <p><span className="bookingSumTitle">Meeting Name</span> <span className="bookingSumRte"><em>:</em> <input type="text" name="meetingName" placeholder="Meeting Name" value={this.state.remark} onChange={this.handleInputRemarkChange} /></span></p>
                                        <p><span className="bookingSumTitle">Staff Name</span> <span className="bookingSumRte"><em>:</em> <input type="text" name="staffName" placeholder="Staff Name" value={this.state.staff} onChange={this.handleInputStaffChange} /></span></p>
                                    </div>
                                </div>

                                <div className="book-btn-wrap text-center">
                                    {/* eslint jsx-a11y/anchor-is-valid: 0 */}
                                    <a className={'btn btn-primary book-now-btn ' + (!(this.state.time && this.state.remark && this.state.staff) && 'disabled')} data-toggle="modal" data-target="#bookingModal">Confirm ></a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="footer">
                        <p>Managed by <strong>H Space Holdings Sdn Bhd</strong></p>
                    </div>
                </div>
                <Modal
                    modalId={'bookingModal'}
                    modalTitle={'Confirm Booking'}
                    modalBody={modalBody}
                    confirmBtnTxt={'Book'}
                    onModalConfirmed={this.onConfirmModal}
                />
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    room: store.room.detail.data,
    isLoading: store.room.detail.isLoading,
    member: store.persist.member,
    error: store.booking.booking.error,
    selected: store.persist.selected,
    bookingSlotInfo: store.booking.bookingSlotInfo.data,
});

const mapDispatchToProp = {
    roomDetail: Actions.roomDetail,
    booking: Actions.booking,
    bookingSlotInfoDispatch: Actions.bookingSlotInfo,
}
export default connect(mapStateToProps, mapDispatchToProp)(Booking);
