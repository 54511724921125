import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Actions from './../actions';

class Logout extends Component {
  onLogout = () => {
    const { selected } = this.props;
    this.props.clearAll();
    this.props.history.push(`/dashboard/${selected.outlet}/${selected.room}`);
  }

  render() {
    return (
      <div className="logout" onClick={this.onLogout}>
        <img src={window.location.origin + '/images/logout.png'} alt="Logout" />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  selected: store.persist.selected,
});

const mapDispatchToProps = {
  clearAll: Actions.clearAll,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
