import Actions from '../actions';

export default function(state = { data: {}, error: null }, action) {
  switch(action.type) {
    case Actions.LOGIN_SUCCESS:
      return { data: action.data, error: null };
    case Actions.LOGIN_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
