
export const SET_CURRENT_OUTLET = 'SET_CURRENT_OUTLET';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_MEMBER = 'SET_MEMBER';
export const SET_BOOKING = 'SET_BOOKING';
export const CLEAR_ALL = 'CLEAR_ALL';

export const setCurrentOutlet = data => ({
  type: SET_CURRENT_OUTLET,
  data,
});

export const setToken = token => ({
  type: SET_TOKEN,
  token
});

export const setMember = member => ({
  type: SET_MEMBER,
  member
});

export const clearAll = () => ({
  type: CLEAR_ALL,
});