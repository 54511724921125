import * as auth from './auth';
import * as persist from './persist';
import * as room from './room';
import * as booking from './booking';

export default {
    ...auth,
    ...persist,
    ...room,
    ...booking,
}