import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import Actions from '../../actions';
import { Link } from 'react-router-dom';
import './Dashboard.css';

class Dashboard extends Component {

   state = {
      curTime: moment().format("hh:mm A"),
      outlet: null,
      room: null,
   }

   componentDidMount() {
      const { match } = this.props;

      let outlet = match.params.outlet;
      let room = match.params.room;
      let todayDate = moment();

      this.setState({
         curTime: moment().format("hh:mm A"),
      })
      this.props.setCurrentOutlet({ outlet, room });

      this.props.roomDetail({ outlet, room, todayDate });
      // Retrieve room detail every 1 min
      setInterval(() => {
         this.props.roomDetail({ outlet, room, todayDate });
      }, 100000);
      // Update current time every 10 secs
      this.timeInterval = setInterval(() => {
         this.setState({ curTime: moment().format("hh:mm A"), })
      }, 10000)
   }

   componentWillUnmount() {
      clearInterval(this.timeInterval);
   }

   filterEndBooking = bookings => {
      return bookings.filter(booking => moment(booking.end_at).isAfter(moment()));
   }

   getCurBooking = bookings => {
      return bookings.filter(booking => moment().isBetween(moment(booking.start_at), moment(booking.end_at)));
   }

   render() {
      const { room } = this.props;
      const booking = this.filterEndBooking(get(room, 'bookings', []));
      const sortedBooking = booking.sort((a, b) => moment(a.start_at) - moment(b.start_at));
      const curBooking = this.getCurBooking(sortedBooking);
      return (
         <div className="dashboard">
            <div className="container-fluid no-padding">
               <div className="page-content mx-auto border-0">
                  <div className="row row-no-space">
                     <div className="col-md-7 col-lg-8 no-padding">
                        <span className="spacer"></span>
                        <div className="meeting_room offset-md-1 offset-lg-2">
                           <h1 className="main_title">{get(room, 'name', '')}</h1>
                           <p className="sub_title">{curBooking.length < 1 ? 'Room Available' : get(curBooking[0], 'remarks', [])}</p>
                           <div className="booking_detail">
                              {curBooking.length >= 1 && (
                                 <div>
                                    <p className="ex">
                                       {moment(get(curBooking[0], 'start_at', [])).format("HHmm")} - {moment(get(curBooking[0], 'end_at', [])).format("HHmm")}
                                       {/* <em>+ Extend</em> */}
                                    </p>
                                    <p className="author">By <strong>{get(curBooking[0], 'member.first_name', [])}</strong></p>
                                 </div>
                              )}
                           </div>
                           <div className={'btn-display ' + (curBooking.length < 1 ? 'available' : 'occupied')}>{(curBooking.length < 1 ? 'AVAILABLE' : 'OCCUPIED NOW')}</div>
                           <div className="footer text-left deskFooter">
                              <p>Managed by <strong>H Space Holdings Sdn Bhd</strong></p>
                           </div>
                        </div>
                     </div>

                     <div className="col-md-5 col-lg-4 no-padding">
                        <div className="meeting_room_sidebar">
                           <h2>{this.state.curTime}</h2>
                           <p className="cr_day">Today<span className="cr_date">{moment().format('MMM DD, YYYY')}</span></p>
                           <ul>
                              <li>
                                 <span className="m_index">No.</span>
                                 <span className="m_dot"></span>
                                 <span className="next_meetings">Next Meeting</span>
                              </li>
                              {sortedBooking.map((item, index) => (
                                 <li key={index}>
                                    <span className="m_index">{index + 1}</span>
                                    <div className="m_dot">{(curBooking.length >= 1 && index === 0) && <span></span>}</div>
                                    <div className="next_meetings">
                                       <div className="title"><strong>{item.remarks}</strong></div>
                                       <span>{moment(item.start_at).format("MMM DD, YYYY")} &nbsp;| &nbsp;{moment(item.start_at).format("HHmm")} - {moment(item.end_at).format("HHmm")}</span>
                                    </div>
                                 </li>
                              ))}
                           </ul>
                           <div className="book-btn-wrap">
                              <Link className="btn btn-primary" to={'/available'}><strong>BOOK ROOM</strong></Link>
                           </div>
                        </div>
                     </div>

                     <div className="footer mobileFooter">
                        <p>Managed by <strong>H Space Holdings Sdn Bhd</strong></p>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      )
   }
}

const mapStateToProps = (store) => ({
   room: store.room.detail.data,
});

const mapDispatchToProp = {
   roomDetail: Actions.roomDetail,
   setCurrentOutlet: Actions.setCurrentOutlet,
}
export default connect(mapStateToProps, mapDispatchToProp)(Dashboard);