import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from '../reducers';
import rootSaga from '../sagas';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const config = {
  key: 'root',
  storage,
  whitelist: ['persist'],
};

let store;
const reducer = persistCombineReducers(config, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middlewares = composeEnhancer(applyMiddleware(sagaMiddleware));

export const getStore = () => store;

const configureStore = () => {
  store = createStore(reducer, middlewares);
  let persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { persistor, store };
};

export default configureStore;