import { fork, all } from 'redux-saga/effects';
import auth from './auth';
import room from './room';
import booking from './booking';

export default function* root() {
  yield all([
    fork(auth),
    fork(room),
    fork(booking),
  ]);
}