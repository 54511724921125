import auth from './auth';
import room from './room';
import booking from './booking';
import persist from './persist';

export default {
    auth,
    room,
    booking,
    persist,
};
