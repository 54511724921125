import React, { Component } from 'react';
import moment from 'moment';

const defaultTimeSlots = [
  {
    id: 1,
    value: '0800',
    isChecked: false
  },
  {
    id: 2,
    value: '0830',
    isChecked: false
  },
  {
    id: 3,
    value: '0900',
    isChecked: false
  },
  {
    id: 4,
    value: '0930',
    isChecked: false
  },
  {
    id: 5,
    value: '1000',
    isChecked: false
  },
  {
    id: 6,
    value: '1030',
    isChecked: false
  },
  {
    id: 7,
    value: '1100',
    isChecked: false
  },
  {
    id: 8,
    value: '1130',
    isChecked: false
  },
  {
    id: 9,
    value: '1200',
    isChecked: false
  },
  {
    id: 10,
    value: '1230',
    isChecked: false
  },
  {
    id: 11,
    value: '1300',
    isChecked: false
  },
  {
    id: 12,
    value: '1330',
    isChecked: false
  },
  {
    id: 13,
    value: '1400',
    isChecked: false
  },
  {
    id: 14,
    value: '1430',
    isChecked: false
  },
  {
    id: 15,
    value: '1500',
    isChecked: false
  },
  {
    id: 16,
    value: '1530',
    isChecked: false
  },
  {
    id: 17,
    value: '1600',
    isChecked: false
  },
  {
    id: 18,
    value: '1630',
    isChecked: false
  },
  {
    id: 19,
    value: '1700',
    isChecked: false
  },
  {
    id: 20,
    value: '1730',
    isChecked: false
  },
  {
    id: 21,
    value: '1800',
    isChecked: false
  },
  {
    id: 22,
    value: '1830',
    isChecked: false
  },
  {
    id: 23,
    value: '1900',
    isChecked: false
  },
  {
    id: 24,
    value: '1930',
    isChecked: false
  },
  {
    id: 25,
    value: '2000',
    isChecked: false
  },
  {
    id: 26,
    value: '2030',
    isChecked: false
  },
  {
    id: 27,
    value: '2100',
    isChecked: false
  },
  {
    id: 28,
    value: '2130',
    isChecked: false
  },
  {
    id: 29,
    value: '2200',
    isChecked: false
  },
  {
    id: 30,
    value: '2230',
    isChecked: false
  },
  {
    id: 31,
    value: '2300',
    isChecked: false
  },
  {
    id: 32,
    value: '2330',
    isChecked: false
  },

]

let startEndCheck = 'start';

class BookingSlot extends Component {

  state = {
    timeSlots: defaultTimeSlots,
    startSlotTime: {},
  }

  componentDidMount() {
    this.resetCheckTimeSlot();
  }

  // Uncheck all timeslot
  resetCheckTimeSlot = () => {
    let timeSlots = this.state.timeSlots;
    timeSlots.forEach(timeSlot => {
      timeSlot.isChecked = false
    })
    this.setState({ timeSlots: timeSlots })
  }

  checkTimeslot = ts => {
    let value = false;
    const { booking } = this.props;
    const sortedBooking = booking.sort((a, b) => moment(a.start_at) - moment(b.start_at));

    for (let i = 0; i < sortedBooking.length; i++) {

      if (startEndCheck === 'start') {
        if (moment(sortedBooking[i].start_at).format('HH:mm') === (moment(ts, 'HH:mm').format('HH:mm'))) {
          value = true;
          startEndCheck = 'end';
          break;
        }
      }

      if (startEndCheck === 'end') {
        if (moment(sortedBooking[i].end_at).format('HH:mm') === (moment(ts, 'HH:mm').format('HH:mm'))) {
          value = false;
          startEndCheck = 'start';
          if (i < sortedBooking.length - 1) {
            if (moment(sortedBooking[i + 1].start_at).format('HH:mm') === (moment(ts, 'HH:mm').format('HH:mm'))) {
              value = true;
              startEndCheck = 'end';
            }
          }
          break;
        }
        value = true;
      }
    }
    if (value)
      return 'slotOccupied';

    return 'slotAvailable';
  }

  onValueChange = event => {
    const { onSlotUpdated, onSlotUpdatedAll } = this.props;
    let timeSlots = this.state.timeSlots;

    // Reset timeslot if user click the timeslot before the selected timeslot
    if (event.target.value < this.state.startSlotTime.value)
      this.resetCheckTimeSlot();

    // Render timeSlot checking
    timeSlots.forEach(timeSlot => {
      if (timeSlot.value === event.target.value)
        timeSlot.isChecked = event.target.checked
    })

    // check the timeslot between 2 checked timeslots
    timeSlots = this.checkTimeSlotInBetween(timeSlots, event.target.value);

    this.setState({ timeSlots });

    if (onSlotUpdated) {
      // Calculate and return Booking slot for Booking.js
      let bookingSlot = this.calculateBookingSlot(timeSlots);
      onSlotUpdated(bookingSlot);
    }

    if (onSlotUpdatedAll) {
      // Return all booking slot for AllRoom.js
      onSlotUpdatedAll(timeSlots);
    }
  };

  checkTimeSlotInBetween = (timeSlots, eventValue) => {
    // to check total checked timeslot
    const totalCheckedSlot = timeSlots.filter(timeSlot => (
      timeSlot.isChecked === true
    ));

    let nextSlotCheck = false;

    if (totalCheckedSlot.length > 1 && eventValue && (eventValue > this.state.startSlotTime.value)) {
      timeSlots.forEach(timeSlot => {
        if (timeSlot.value === this.state.startSlotTime.value) {
          nextSlotCheck = true;
        }
        if (nextSlotCheck) {
          timeSlot.isChecked = true;
        } else {
          timeSlot.isChecked = false;
        }
        if (timeSlot.value === eventValue) {
          nextSlotCheck = false;
        }
      })
    }
    return timeSlots;
  }

  calculateBookingSlot = timeSlots => {
    let firstSlot = null;
    let lastSlot = null;
    let calculatedSlot = null;

    let filterBookingSlot = timeSlots.filter(timeSlot => {
      return timeSlot.isChecked === true;
    });

    if (filterBookingSlot[0]) {
      this.setState({ startSlotTime: filterBookingSlot[0] });
      firstSlot = filterBookingSlot[0].value;
      lastSlot = moment(filterBookingSlot[filterBookingSlot.length - 1].value, 'HHmm').add(30, 'minutes').format("HHmm");
      calculatedSlot = firstSlot.replace(/00/g, '').replace(/30/g, '.5');
    }

    let bookingObj = {
      from: (
        calculatedSlot
      ),
      duration: (
        filterBookingSlot.length
      ),
      time: (firstSlot && firstSlot + '-' + lastSlot)
    }
    return bookingObj;
  }

  render() {
    const { roomId } = this.props;
    return (
      <div className='scroll-container'>
        {
          this.state.timeSlots.map((ts, index) => (
            <label key={index} className={this.checkTimeslot(ts.value) + ' ' + (ts.isChecked ? 'check' : '')} onClick={this.onValueChange}>
              <input type="checkbox" id={roomId + ts.value} value={ts.value} className="hidden" />{ts.value}
            </label>
          ))}
      </div>
    )
  }
};

export default BookingSlot;
