import Actions from '../actions';
import { combineReducers } from 'redux';

function all(state = { data: {}, isLoading: false, error: null }, action) {
  switch(action.type) {
    case Actions.ALL_ROOM:
      return { data: {}, isLoading: true, error: null};
    case Actions.ALL_ROOM_SUCCESS:
      return { data: action.data, isLoading: false, error: null};
    case Actions.ALL_ROOM_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

function detail(state = { data: {}, isLoading: false, error: null }, action) {
  switch(action.type) {
    case Actions.ROOM_DETAIL:
      return { data: {}, isLoading: true, error: null};
    case Actions.ROOM_DETAIL_SUCCESS:
      return { data: action.data, isLoading: false, error: null };
    case Actions.ROOM_DETAIL_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

export default combineReducers({
  detail,
  all,
})