import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Room.css';
import moment from 'moment';
import get from 'lodash/get';
import Actions from '../../actions';
import DatePicker from 'react-date-picker';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import BookingSlot from '../../components/BookingSlot';
import Loading from '../../components/Loading';
import Logout from '../../components/Logout';
import { Link } from "react-router-dom";

class AllRoom extends Component {

    state = {
        curTime: moment().format("hh:mm A"),
        selectedDate: new Date(),
        timeSlots: {},
    }

    onChange = date => {
        const { selected } = this.props;
        const { selectedDate } = this.state;
        this.setState({ selectedDate: date });
        this.props.allRoom({ outlet: selected.outlet, selectedDate });
    };

    componentDidMount() {
        const { selected } = this.props;
        const { selectedDate } = this.state;

        this.setState({
            curTime: moment().format("hh:mm A"),
        })
        this.props.allRoom({ outlet: selected.outlet, selectedDate });

        // Update current time every 10 secs
        this.timeInterval = setInterval(() => {
            this.setState({ curTime: moment().format("hh:mm A"), })
        }, 10000)
    }

    componentWillUnmount() {
        clearInterval(this.timeInterval);
    }

    onUpdateSlot = timeSlot => {
        this.setState({
            timeSlots: timeSlot,
        })
    }

    onSubmit = event => {
        const { timeSlots, selectedDate } = this.state;
        const room = event.target.id;

        let bookingObj = {
            timeSlots,
            selectedDate: moment(selectedDate)
        };

        this.props.bookingSlotInfo(bookingObj);
        this.props.history.push(`/booking/${room}`);
    }

    render() {
        const { outlet, isLoading } = this.props;
        const { selectedDate } = this.state;
        const rooms = get(outlet, 'rooms', []);
        return (
            <div className="main">
                <div className="container mt-4 mt-md-5">
                    <Link className="lightText back" to={`/available`}>Back</Link>
                    <Logout />
                    <div className="row mainLocations">
                        <div className="col-2 col-md-2 col-lg-2">
                            <div className="innerRoomsLink"><img src={window.location.origin + '/images/logo-light.png'} alt="HSpace Logo" /></div>
                        </div>
                        <div className="col-5 col-md-7 col-lg-5 leftTextTime">
                            <p>Location for service</p>
                            <h3>{get(outlet, 'name', [])}</h3>
                            <span>{moment(selectedDate).format('D MMMM YYYY')}</span>
                            <DatePicker
                                onChange={this.onChange}
                                value={selectedDate}
                                clearIcon={null}
                                calendarIcon="Change Date"
                                className="hspace-date-picker"
                                formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}
                            />
                        </div>
                        <div className="col-5 col-md-3 col-lg-5 lightTextTime">
                            <span className="mnTime">{this.state.curTime}</span>
                            <span className="mnDate">{moment().format('MMM DD, YYYY')}</span>
                            {/* <a className="mnFind">Find a room</a> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-12 mt-5 bookingMain">
                            <div className="bookingSlot">
                                {isLoading && <Loading />}
                                {rooms.map((room, index) => (
                                    <form action="" method="post" key={index}>
                                        <div className="form-group">
                                            <label className="room-name">{get(room, 'name', [])}</label>
                                            <div className="img_border">
                                                <img src={get(room, 'photo', [])} alt={get(rooms, 'name', [])} className="room-image" />
                                            </div>
                                            <PerfectScrollbar>
                                                <BookingSlot booking={get(room, 'bookings', [])} roomId={get(room, 'id', [])} onSlotUpdatedAll={this.onUpdateSlot} />
                                            </PerfectScrollbar>
                                        </div>
                                        <div className="book-room">
                                            {/* eslint jsx-a11y/anchor-is-valid: 0 */}
                                            <strong><a className="btn btn-secondary" type="submit" onClick={this.onSubmit} id={get(room, 'id', [])}>Book Room</a></strong>
                                        </div>
                                    </form>
                                )
                                )}
                                <ul>
                                    <li><span className="bookingAvailable">.</span> Available</li>
                                    <li><span className="bookingOccupied">.</span> Occupied</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <p>Managed by <strong>H Space Holdings Sdn Bhd</strong></p>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => ({
    outlet: store.room.all.data,
    isLoading: store.room.all.isLoading,
    selected: store.persist.selected,
});

const mapDispatchToProps = {
    allRoom: Actions.allRoom,
    bookingSlotInfo: Actions.bookingSlotInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(AllRoom);