import React, { Component } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import './Confirm.css';
import Actions from '../../actions';

class Confirm extends Component {

    onClose = () => {
        const { selected } = this.props;
        this.props.clearAll();
        this.props.history.push(`/dashboard/${selected.outlet}/${selected.room}`);
    }

    render() {
        const { booking } = this.props;
        const creditRate = get(booking, 'booking.credit_cost', [])

        return (
            <div className="confirm">
                <div className="container mt-4 mt-md-5">
                    <div className="page-content mx-auto border-0 text-center">
                        <div className="inner-content-wrap">
                            <p>You Booking has confirmed.</p>
                            <p><strong>{creditRate}</strong> credit(s) will be deducted from your account.</p>
                            <p className="lastChild">Thank you for booking.</p>
                        </div>
                        <div className="book-btn-wrap">
                            {/* eslint jsx-a11y/anchor-is-valid: 0 */}
                            <a className="btn btn-primary" type="submit" onClick={this.onClose}>Close</a>
                        </div>
                        <p className="info">To cancel or change a booking, please contact your office manager or receptionist.</p>
                    </div>
                    <div className="footer">
                        <p>Managed by <strong>H Space Holdings Sdn Bhd</strong></p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    booking: store.booking.booking.data,
    outlet: store.room.all.data,
    selected: store.persist.selected,
});

const mapDispatchToProps = {
    clearAll: Actions.clearAll,
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);

