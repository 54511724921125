import Actions from '../actions';
import { combineReducers } from 'redux';

function credit(state = { data: [], error: null }, action) {
  switch(action.type) {
    case Actions.CREDIT_DETAIL_SUCCESS:
      return { data: action.data, error: null };
    case Actions.CREDIT_DETAIL_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

function booking(state = { data: [], error: null }, action) {
  switch(action.type) {
    case Actions.BOOKING_SUCCESS:
      return { data: action.data, error: null };
    case Actions.BOOKING_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

function bookingSlotInfo(state = { data: [], error: null }, action) {
  switch(action.type) {
    case Actions.BOOKING_SLOT_INFO:
      return { data: action.data, error: null };
    default:
      return state;
  }
};

export default combineReducers({
  credit,
  booking,
  bookingSlotInfo,
})