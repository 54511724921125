import Actions from '../actions';
import { combineReducers } from 'redux';

function token(state = null, action) {
  switch (action.type) {
    case Actions.SET_TOKEN:
      return action.token;
    case Actions.CLEAR_ALL:
      return null;
    default:
      return state;
  }
};

function selected(state = { outlet: null, room: null }, action) {
  switch (action.type) {
    case Actions.SET_CURRENT_OUTLET:
      return { outlet: action.data.outlet, room: action.data.room };
    default:
      return state;
  }
};

function member(state = {}, action) {
  switch (action.type) {
    case Actions.SET_MEMBER:
      return action.member;
    case Actions.CLEAR_ALL:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  token,
  selected,
  member,
})