import { put, call, takeLatest, all, fork } from 'redux-saga/effects';
import { loginUserService } from '../services/auth';
import Actions from '../actions';

function* login({credentials, callback}) {
  try {
    const response = yield call(loginUserService, credentials);
    if (response.status !== 200) {
      yield put(Actions.loginError(response));
    } else {
      yield put(Actions.loginSuccess(response));
      yield put(Actions.setToken(response.access_token));
      yield put(Actions.setMember(response.member));
      yield call(callback, response);
    }
  } catch(error) {
    yield put(Actions.loginError(error));
  }
}

function* watchLogin() {
  yield takeLatest(Actions.LOGIN, login);
}

export default function* auth() {
  yield all([
    fork(watchLogin),
  ]);
}