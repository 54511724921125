export const CREDIT_DETAIL = 'CREDIT_DETAIL';
export const CREDIT_DETAIL_SUCCESS = 'CREDIT_DETAIL_SUCCESS';
export const CREDIT_DETAIL_ERROR = 'CREDIT_DETAIL_ERROR';

export const BOOKING = 'BOOKING_DETAIL';
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';
export const BOOKING_ERROR = 'BOOKING_ERROR';

export const BOOKING_SLOT_INFO = 'BOOKING_SLOT_INFO';

export const creditDetail = () => ({
  type: CREDIT_DETAIL,
});

export const creditDetailSuccess = data => ({
  type: CREDIT_DETAIL_SUCCESS,
  data,
});

export const creditDetailError = error => ({
  type: CREDIT_DETAIL_ERROR,
  error,
});

export const booking = (params, callback) => ({
  type: BOOKING,
  params,
  callback
});

export const bookingSuccess = data => ({
  type: BOOKING_SUCCESS,
  data,
});

export const bookingError = error => ({
  type: BOOKING_ERROR,
  error,
});

export const bookingSlotInfo = data => ({
  type: BOOKING_SLOT_INFO,
  data, 
});