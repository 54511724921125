import React, { Component } from 'react';
import './Login.css';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Actions from '../../actions';

class Login extends Component {

    state = {
        outlet: null,
        room: null,
    }

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    onHandleLogin = (event) => {
        event.preventDefault();

        let email = event.target.email.value;
        let password = event.target.password.value;

        const credentials = {
            email, password
        };

        this.props.login(credentials, this.onLoginSuccess);
    }

    onLoginSuccess = response => {
        if (this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname) {
            this.props.history.push(this.props.location.state.from.pathname);
        } else {
            const route = `${this.props.selected.outlet}/${this.props.selected.room}/available`;
            this.props.history.push(route);
        }
    }

    render() {
        const { error } = this.props;
        
        return (
            <div className="login">
                <div className="container mt-4 mt-md-5">
                    {/* eslint jsx-a11y/anchor-is-valid: 0 */}
                    <a className="lightText back" onClick={this.goBack} href={null}>Back</a>
                    <div className="card mx-auto border-0">
                        <div className="card-header border-bottom-0 bg-transparent">
                            <div className="logo"><img src="/images/logo.png" alt="" /></div>
                        </div>
                        <div className="card-body pb-4">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="pills-login-tab">
                                    <form onSubmit={this.onHandleLogin}>
                                        <div className="form-group">
                                            <input type="email" name="email" className="form-control" id="email" placeholder="Email ID" required autoFocus />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="password" className="form-control" id="password" placeholder="Password" minLength="8" required />
                                        </div>
                                        <div className="error-message">
                                            {get(error, 'errors[0]', [])}
                                        </div>
                                        <div className="row row-no-space check-control">
                                            {/* <div className="custom-control custom-checkbox col-6">
                                                <input className="custom-control-input" id="rememberMe" type="checkbox" />
                                                <label className="custom-control-label" htmlFor="rememberMe">Remember me</label>
                                            </div> */}
                                            {/* <div className="text-center col-6"> */}
                                                <a className="text-primary" href='https://v2.hspace.co/member/password/reset'>Forgot Password?</a>
                                            {/* </div> */}
                                        </div>
                                        <div className="text-center pt-4">
                                            <button type="submit" className="btn btn-primary">Member Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <ul className="policy-links">
                        <li>Terms of use</li>
                        <li>Privacy Policy</li>
                    </ul> */}
                    <div className="footer">
                        <p>Managed by <strong>H Space Holdings Sdn Bhd</strong></p>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => ({
    auth: store.auth.data,
    error: store.auth.error,
    selected: store.persist.selected,
});

const mapDispatchToProps = {
    login: Actions.login,
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);

