import moment from 'moment';
import { getStore } from '../stores/configueStore';

const API_URL = process.env.REACT_APP_API_URL;

const getToken = () => {
    const store = getStore();
    const state = store.getState();
    const token = state.persist.token;
    if (token) {
        return token;
    }
    return null;
};

export const roomDetailService = (params) => {
  const ROOM_API_ENDPOINT = `${API_URL}/outlet/`+params.outlet+'/'+params.room+'?date=' + moment(params.selectedDate).format('YYYY-MM-DD');
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  };

  return fetch(ROOM_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const allRoomService = (params) => {
  const ROOM_API_ENDPOINT = `${API_URL}/outlet/`+params.outlet+'?date=' + moment(params.selectedDate).format('YYYY-MM-DD');
  const token = getToken();
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': `Bearer ${token}`,
    },
  };

  return fetch(ROOM_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

