import React, { Component } from 'react';
import { connect } from 'react-redux';
import Actions from '../actions';
import './Modal.css';

class Modal extends Component {
    onModalConfirm = () => {
        const { onModalConfirmed } = this.props;
        onModalConfirmed();
    }

    render() {
        const { modalId, modalTitle, modalBody, confirmBtnTxt } = this.props;
        return (
            <div className="modal fade" id={modalId}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalTitle}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {modalBody}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.onModalConfirm} data-dismiss="modal">{confirmBtnTxt}</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    selected: store.persist.selected,
});

const mapDispatchToProps = {
    clearAll: Actions.clearAll,
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
