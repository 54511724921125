export const ROOM_DETAIL = 'ROOM_DETAIL';
export const ROOM_DETAIL_SUCCESS = 'ROOM_DETAIL_SUCCESS';
export const ROOM_DETAIL_ERROR = 'ROOM_DETAIL_ERROR';

export const ALL_ROOM = 'ALL_ROOM';
export const ALL_ROOM_SUCCESS = 'ALL_ROOM_SUCCESS';
export const ALL_ROOM_ERROR = 'ALL_ROOM_ERROR';

export const roomDetail = params => ({
  type: ROOM_DETAIL,
  params,
});

export const roomDetailSuccess = data => ({
  type: ROOM_DETAIL_SUCCESS,
  data,
});

export const roomDetailError = error => ({
  type: ROOM_DETAIL_ERROR,
  error,
});

export const allRoom = params => ({
  type: ALL_ROOM,
  params,
});

export const allRoomSuccess = data => ({
  type: ALL_ROOM_SUCCESS,
  data,
});

export const allRoomError = error => ({
  type: ALL_ROOM_ERROR,
  error,
});