const API_URL = process.env.REACT_APP_API_URL;

export const loginUserService = (credentials) => {
    const LOGIN_API_ENDPOINT = `${API_URL}/login`;
  
    const parameters = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: JSON.stringify(credentials)
    };
  
    return fetch(LOGIN_API_ENDPOINT, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      });
  };