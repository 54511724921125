import React from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default () => (
    <div className="loading">
        <Loader
            type="ThreeDots"
            color="#ffd400"
            height={100}
            width={100}
        />
    </div>
);