import React, { Component } from "react";
import "./Room.css";
import moment from "moment";
import get from "lodash/get";
import Actions from "../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from '../../components/Loading';
import Logout from '../../components/Logout';

// Calculate next available time until
const start = moment();
const remainder = 60 - (start.minute() % 30);
const roomAvaibleTime = moment(start).add(remainder, "minutes").set({ second: 0, millisecond: 0 });

class AvailableRoom extends Component {
    state = {
        curTime: moment().format("hh:mm A"),
    };

    componentDidMount() {
        const { selected } = this.props;

        this.setState({
            curTime: moment().format("hh:mm A"),
            room: selected.room,
        });

        this.props.allRoom({ outlet: selected.outlet, });
        this.props.creditDetail();

        // Update current time every 10 secs
        this.timeInterval = setInterval(() => {
            this.setState({ curTime: moment().format("hh:mm A"), })
        }, 10000)
    }

    componentWillUnmount() {
        clearInterval(this.timeInterval);
    }

    filterUnavailableRoom = rooms => {
        let filteredUnavailableRoom = rooms.filter(this.filterBooking);
        return filteredUnavailableRoom;
    }

    getMomentTime = (m) => {
        return moment({ hour: moment().hours(m), minute: moment().minutes(m) });
    };

    filterBooking = rooms => {
        let booking = rooms.bookings;

        for (let i = 0; i < booking.length; i++) {
            if (moment(roomAvaibleTime).isBetween(moment(booking[i].start_at), moment(booking[i].end_at), null, '[]')) {
                return false;
            }
        }
        return true;
    }

    handleInputChange = event => {
        let roomId = event.target.id.substring(2, event.target.id.length);

        this.setState({
            room: roomId,
        })
    }

    render() {
        const { credit, member, outlet, isLoading, selected } = this.props;
        const rooms = get(outlet, 'rooms', []);
        let filteredRoom = this.filterUnavailableRoom(rooms);
        let sortedRoom = filteredRoom.sort((a, b) => moment(a.start_at) - moment(b.start_at));

        return (
            <div className="profile">
                <div className="container mt-4 mt-md-5">
                    <Link className="lightText back" to={`/dashboard/${selected.outlet}/${selected.room}`}>Back</Link>
                    <Logout />
                    <span className="lightTextTime">{this.state.curTime}</span>
                    <div className="row">
                        <div className="col-md-4 col-lg-4 profileSidebar">
                            <img src="/images/user.png" width="142" alt="" />
                            <h4 className="memberName">{get(member, "first_name", [])} {get(member, "last_name", [])}</h4>
                            <div className="accountDetails">
                                <p>Account Details</p>
                                <span className="acCredit">
                                    {get(credit, "storeCreditBalance", [])} credits(s)
                                </span>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-8 profileMainContent">
                            <h2>AVAILABLE ROOMS NOW</h2>
                            <div className="row text-center">
                                {isLoading && <Loading />}
                                {sortedRoom.map((room, index) => {
                                    return (
                                        <div className="col-6 col-md-3 col-lg-3 no-padding" key={index}>
                                            {/* eslint jsx-a11y/anchor-is-valid: 0 */}
                                            <a className="innerRoomsLink">
                                                <input type="checkbox" id={'cb' + room.id} onChange={this.handleInputChange} checked={room.id.toString() === this.state.room} />
                                                <label key={index} htmlFor={'cb' + room.id}>
                                                    <div className="img_border">
                                                        <img src={get(room, 'photo', [])} alt={get(rooms, 'name', [])} />
                                                    </div>
                                                    <p>{get(room, 'name', [])}</p>
                                                </label>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="book-btn-wrap">
                                <Link to={`/booking/${this.state.room}`}><div className="btn btn-primary">BOOK NOW</div></Link>
                                <Link to={"/all"} className="btn btn-secondary">View All Rooms</Link>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <p>
                            Managed by <strong>H Space Holdings Sdn Bhd</strong>
                        </p>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = store => ({
    credit: store.booking.credit.data,
    member: store.persist.member,
    outlet: store.room.all.data,
    isLoading: store.room.all.isLoading,
    selected: store.persist.selected,
});

const mapDispatchToProps = {
    creditDetail: Actions.creditDetail,
    allRoom: Actions.allRoom
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailableRoom);
