import { getStore } from '../stores/configueStore';

const API_URL = process.env.REACT_APP_API_URL;

const getToken = () => {
    const store = getStore();
    const state = store.getState();
    const token = state.persist.token;
    if (token) {
        return token;
    }
    return null;
};

// Retrive Booking Credit
export const bookingCreditService = () => {
    const ROOM_API_ENDPOINT = `${API_URL}/bookings/create`;
    const token = getToken();
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${token}`,
        },
    };

    return fetch(ROOM_API_ENDPOINT, parameters)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

// Book room
export const bookingRoomService = (params) => {
    const ROOM_API_ENDPOINT = `${API_URL}/bookings`;
    const token = getToken();
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(params)
    };

    return fetch(ROOM_API_ENDPOINT, parameters)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};