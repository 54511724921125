
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const login = (credentials, callback) => ({
  type: LOGIN,
  credentials,
  callback,
});

export const loginSuccess = data => ({
  type: LOGIN_SUCCESS,
  data,
});

export const loginError = error => ({
  type: LOGIN_ERROR,
  error
});

