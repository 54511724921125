import { put, call, takeLatest, all, fork } from 'redux-saga/effects';
import { bookingCreditService, bookingRoomService } from '../services/booking';
import Actions from '../actions';

function* creditDetail() {
    try {
        const response = yield call(bookingCreditService);
        yield put(Actions.creditDetailSuccess(response));
    } catch (error) {
        yield put(Actions.creditDetailError(error));
    }
}

function* bookingRoom({ params, callback }) {
    try {
        const response = yield call(bookingRoomService, params);
        if (response.status !== 200) {
            yield put(Actions.bookingError(response));
        } else {
            yield put(Actions.bookingSuccess(response));
            yield call(callback, response);
        }
    } catch (error) {
        yield put(Actions.bookingError(error));
    }
}

function* watchCreditDetail() {
    yield takeLatest(Actions.CREDIT_DETAIL, creditDetail);
}

function* watchBooking() {
    yield takeLatest(Actions.BOOKING, bookingRoom);
}

export default function* booking() {
    yield all([
        fork(watchCreditDetail),
        fork(watchBooking),
    ]);
}