import { put, call, takeLatest, all, fork } from 'redux-saga/effects';
import { roomDetailService, allRoomService } from '../services/room';
import Actions from '../actions';

function* roomDetail({params}) {
  try {
    const response = yield call(roomDetailService, params);
    yield put(Actions.roomDetailSuccess(response.room));
  } catch(error) {
    yield put(Actions.roomDetailError(error));
  }
}

function* allRoom({params}) {
  try {
    const response = yield call(allRoomService, params);
    yield put(Actions.allRoomSuccess(response.outlet));
  } catch(error) {
    yield put(Actions.allRoomError(error))
  }
}

function* watchRoomDetail() {
  yield takeLatest(Actions.ROOM_DETAIL, roomDetail);
}

function* watchAllRoom() {
  yield takeLatest(Actions.ALL_ROOM, allRoom);
}

export default function* room() {
  yield all([
    fork(watchRoomDetail),
    fork(watchAllRoom),
  ]);
}