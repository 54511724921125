import React, { Component } from 'react';
import {getStore} from './stores/configueStore';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Confirm from './pages/confirm';
import Booking from './pages/booking';
import AllRoom from './pages/room/AllRoom';
import AvailableRoom from './pages/room/AvailableRoom';
import Logout from './components/Logout';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const store = getStore();
        const state = store.getState();
        const token = state.persist.token;
        if (token) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
}

class App extends Component {
  render() {
    return (
      <Router>
        
        <div className="App">
            <Route exact path="/dashboard/:outlet/:room" component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/confirm" component={Confirm} />
            <PrivateRoute path="/booking/:room">
              <Route component={Booking}/>
            </PrivateRoute>
            <PrivateRoute path="/all">
              <Route component={AllRoom} />
            </PrivateRoute>
            <PrivateRoute path="/available">
              <Route component={AvailableRoom} />
            </PrivateRoute>
            <Route exact path="/logout" component={Logout} />
        </div>
      </Router>
    );
  }
}

export default App;
